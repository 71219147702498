import SvgIcon from '@mui/material/SvgIcon';

export default function AppLogo({ sx }) {
  return (
    <SvgIcon sx={sx}>
      <svg viewBox="12 12 365 365" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient
            id="gradient-1-0"
            gradientUnits="userSpaceOnUse"
            x1="115"
            y1="126"
            x2="115"
            y2="335"
            gradientTransform="matrix(1, 0, 0, 1.36, 4.63, -122)"
            xlinkHref="#gradient-1"
          />
          <linearGradient id="gradient-1">
            <stop offset="0" style={{ stopColor: 'rgb(125, 254, 1)' }} />
            <stop offset="1" style={{ stopColor: 'rgb(3, 125, 17)' }} />
          </linearGradient>
          <linearGradient
            id="gradient-1-1"
            gradientUnits="userSpaceOnUse"
            x1="275"
            y1="47"
            x2="275"
            y2="334"
            xlinkHref="#gradient-1"
          />
          <linearGradient
            id="gradient-1-2"
            gradientUnits="userSpaceOnUse"
            x1="275"
            y1="236"
            x2="275"
            y2="334"
            gradientTransform="matrix(1, 0, 0, 2.9, 0.95, -637)"
            xlinkHref="#gradient-1"
          />
        </defs>
        <path
          opacity="1"
          stroke="none"
          d="M 110.427 131.07 C 114.889 125.188 114.893 125.182 119.831 130.333 C 121.65 132.229 199.825 212.231 208.219 220.509 C 208.252 256.84 208.224 293.172 208.394 329.502 C 208.415 333.991 206.891 334.848 203.381 334.831 C 176.725 334.703 60.105 334.663 28.453 334.872 C 23.817 334.903 22.109 333.722 22.154 328.011 C 22.425 293.201 22.366 258.387 22.861 222.929 C 24.015 221.195 24.724 220.106 25.433 219.018 M 119.978 305.281 C 132.307 305.123 171.304 305.04 177.962 305.29 C 181.479 305.422 182.535 303.984 182.51 300.036 C 182.376 278.536 182.543 257.033 182.312 235.534 C 182.286 233.086 180.941 230.013 179.329 228.327 C 159.668 207.769 139.848 187.41 120.048 167.027 C 118.919 165.865 117.604 164.94 115.808 163.426 C 115.572 165.614 115.353 166.697 115.352 167.779 C 115.334 211.924 115.377 256.068 115.267 300.213 C 115.257 304.376 116.664 305.324 119.978 305.281 Z"
          style={{ fill: 'url("#gradient-1-0")' }}
          transform="matrix(1, 0, 0, 1, 0, 0)"
        />
        <path
          opacity="1"
          stroke="none"
          d="M 182.516 161.745 C 182.516 137.997 182.516 114.249 182.516 89.651 C 185.006 89.651 187.118 89.652 189.23 89.651 C 211.896 89.649 234.562 89.532 257.227 89.743 C 261.548 89.784 263.095 88.445 262.934 83.335 C 262.617 73.262 262.964 63.163 262.79 53.081 C 262.722 49.139 263.859 47.653 267.379 47.823 C 272.868 48.087 278.388 48.156 283.871 47.806 C 287.999 47.542 288.943 49.513 288.846 53.81 C 288.628 63.51 288.991 73.228 288.697 82.923 C 288.543 88.028 289.806 89.818 294.527 89.763 C 317.357 89.5 340.193 89.826 363.022 89.516 C 368.126 89.446 369.4 91.114 369.387 96.865 C 369.217 173.561 369.275 250.257 369.271 326.954 C 369.271 334.503 369.257 334.513 362.811 334.537 C 358.145 334.555 353.456 334.217 348.818 334.636 C 344.287 335.045 343.214 333.01 343.231 328.115 C 343.378 285.676 343.231 150.552 343.389 125.432 C 343.418 120.8 342.282 119.083 338.053 119.153 C 323.39 119.393 308.72 119.395 294.057 119.15 C 289.85 119.08 288.67 120.741 288.699 125.386 C 288.856 150.507 288.646 175.63 288.875 200.749 C 288.922 205.905 287.504 207.417 283.177 207.081 C 278.2 206.695 273.169 206.727 268.188 207.074 C 264.075 207.36 262.726 205.806 262.762 201.001 C 262.946 176.262 262.853 151.521 262.836 126.78 C 262.831 119.272 262.801 119.252 256.256 119.246 C 242.59 119.232 228.924 119.235 215.258 119.244 C 208.353 119.248 208.341 119.259 208.33 126.905 C 208.3 146.315 208.281 165.726 207.849 185.526 C 205.348 183.768 188.732 167.653 182.516 161.745 Z"
          style={{ fill: 'url("#gradient-1-1")' }}
          transform="matrix(1, 0, 0, 1, 0, 0)"
        />
        <path
          opacity="1"
          stroke="none"
          d="M 284.961 236.474 C 286.519 237.708 288.595 238.929 288.601 240.163 C 288.734 271.108 288.65 302.056 288.584 333.002 C 288.584 333.334 288.315 333.665 288.002 334.389 C 279.956 334.389 271.746 334.389 263.14 334.389 C 263.031 332.294 262.851 330.455 262.85 328.615 C 262.832 300.132 262.93 271.647 262.751 243.165 C 262.72 238.1 263.797 235.947 268.557 236.357 C 273.848 236.813 279.192 236.464 284.961 236.474 Z"
          style={{ fill: 'url("#gradient-1-2")' }}
          transform="matrix(1, 0, 0, 1, 0, 0)"
        />
        <path
          fill="#CCE177"
          opacity="1"
          stroke="none"
          d="M 182.426 162.301 C 188.732 167.653 205.348 183.768 207.738 186.06 C 208.174 197.273 208.312 208.342 208.334 219.96 C 199.825 212.231 191.431 203.953 182.797 195.106 C 182.484 194.12 182.335 173 182.426 162.301 Z"
          transform="matrix(1, 0, 0, 1, 0, 0)"
        />
      </svg>
    </SvgIcon>
  );
}
